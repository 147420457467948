import '../../public/fonts/Theinhardt.css'
import '../../public/fonts/Cambon.css'
import '../styles/globals.css'
import '../styles/variables.scss'
import '@getethos/ethos-design-system-v2/dist/style.css'
import '../styles/tailwind.css'
import '../styles/style.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { useEffect } from 'react'

import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useReportWebVitals } from 'next/web-vitals'

import {
  CIO_COOKIE_KEY,
  CIO_QUERY_PARAM,
  CMS_REFERRAL_PATH_COOKIE_KEY,
} from '@/constants'
import { formatWebVitalsMetric } from '@/helpers/webVitals'
import { OptimizelyProvider, ReactSDKClient } from '@optimizely/react-sdk'
import Cookies from 'js-cookie'
import { coreWebVitalsAnalytics } from 'lib/@getethos/analytics/analyticsEvents'
import { getAndSetAnonymousIDFromParamClientSide } from 'lib/@getethos/analytics/anonymousID'
import { setCookieDefaults } from 'lib/@getethos/cookies'
import { optimizelyInstance } from 'lib/FeaturesAndExperiments/optimizelyInstance'

import { useAnalytics } from '@/hooks/useAnalytics'
import { useLandingPageAttributes } from '@/hooks/useLandingPageAttributes'

export default function MyApp({ Component, pageProps }: AppProps) {
  const { asPath } = useRouter()

  const instance = optimizelyInstance()
  const { loadAnalytics, pageTrack } = useAnalytics()
  if (typeof window !== 'undefined') {
    setCookieDefaults()
  }
  const { landingPageAttributes, userId } = useLandingPageAttributes()
  useReportWebVitals((metric) => {
    const event = formatWebVitalsMetric(metric)
    if (!event) return
    coreWebVitalsAnalytics.trackWebVitals({
      properties: {
        ...event,
        url: asPath,
        userId,
        deviceType: landingPageAttributes.deviceType,
      },
    })
  })

  useEffect(() => {
    loadAnalytics()
    getAndSetAnonymousIDFromParamClientSide()
  }, [])

  useEffect(() => {
    pageTrack()
    if (
      typeof window !== 'undefined' &&
      !window.location.pathname.includes('/app')
    ) {
      Cookies.set(CMS_REFERRAL_PATH_COOKIE_KEY, window.location.pathname, {
        expires: 14,
        secure: true,
      })
    }
  }, [asPath])

  useEffect(() => {
    const params = new URLSearchParams(document.location.search.slice(1))
    const cioToken = params.get(CIO_QUERY_PARAM)
    if (!cioToken) return
    Cookies.set(CIO_COOKIE_KEY, window.btoa(cioToken), {
      expires: 14,
      secure: true,
    })
  }, [])

  const pathnameOnly = asPath.split('?') // split at query string parameters
  const userInfo = {
    id: userId,
    attributes: {
      client: 'CMS',
      pathname: pathnameOnly[0],
      ...landingPageAttributes,
    },
  }

  return (
    <OptimizelyProvider optimizely={instance as ReactSDKClient} user={userInfo}>
      <div className="CmsLayoutRoot">
        <Component {...pageProps} />
      </div>
    </OptimizelyProvider>
  )
}
